import Toasted from "vue-toasted"
import Vue from 'vue'
import VeeValidate from 'vee-validate'
import VueScrollTo from 'vue-scrollto'
import dictionary from './lang/messages'
import store from './store/index'
import vSelect from "vue-select";
import MmenuLight from 'mmenu-light';
import Mmenu from 'mmenu-js';
import VueRouter from 'vue-router';
import BSN from 'bootstrap.native/dist/bootstrap-native-v4.js';
// import my from "flatpickr/src/l10n/my";

Vue.use(VueRouter);
Vue.use(Toasted);
Vue.use(VueScrollTo);

Vue.use(VeeValidate, {
    locale: 'es',
    dictionary: {
        es: dictionary.es
    }
});

const router = new VueRouter({
    mode: 'history'
});


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding vue-components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
Vue.component("v-select", vSelect);
Vue.component('page-cover', require('./vue-components/navigation/page-cover/page-cover').default)
Vue.component('dropdown', require('./vue-components/atomic/dropdown/dropdown').default)
Vue.component('wapp', require('./vue-components/atomic/wapp/wapp').default)
// Vue.component('contact', require('./vue-components/atomic/contactForm/contact_form').default)

export const Channel = new Vue();

Vue.mixin({
    data() {
        return {
            baseUrl: '/api',
            authUrl: 'user',
            cartUrl: 'carrito/fetch',
            categoriesUrl: 'categorias',
            loggedIn: auth, //Fetched from const auth on main.blade
            theme: active_theme,
        }
    },
    methods: {
        toast(type, text, duration = 1500) {
            eval(`this.$toasted.${type}('${text}', {
                theme: 'outline',
                duration:${duration}
        })`);
        },
        formatCurrency(n, c, d, t) {
            var c = isNaN(c = Math.abs(c)) ? 2 : c,
                d = d == undefined ? "." : d,
                t = t == undefined ? "," : t,
                s = n < 0 ? "-" : "",
                i = String(parseInt(n = Math.abs(Number(n) || 0).toFixed(c))),
                j = (j = i.length) > 3 ? j % 3 : 0;

            return '' + s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t);
        },
        asset(path) {
            return '/themes/' + this.theme + '/dist/' + path
        },
        parseResponse({ message, status }) {
            switch (status) {
                case 'ERROR':
                    this.toast('error', message);
                    break;
                case 'WARNING':
                    this.toast('warning', message);
                    break;
            }
        },
        BSN() {
            // first, we reference the .nav component that holds all tabs
            var myTabs = document.querySelectorAll('.bsn_tabs');
            var collapse = document.querySelectorAll('.collapseLink');
            // find all elements with title attribute
            var elementsTooltip = document.querySelectorAll('[title]');

            // attach a tooltip for each
            if (elementsTooltip) {
                Array.from(elementsTooltip).map(
                    tip => new BSN.Tooltip(tip, {
                        placement: 'top', //string
                        animation: 'slideNfade', // CSS class
                        delay: 150, // integer
                    })
                )
            }

            if (myTabs) {
                // let's give the initialization a JavaScript reference for the "target" option
                Array.from(myTabs).forEach((el) => {
                    var myTabsCollection = el.querySelectorAll('a');

                    if (myTabsCollection) {
                        // initialize the component for all items in the collection
                        Array.from(myTabsCollection).forEach((tab) => {
                            new BSN.Tab(tab, { height: true })
                        }
                        );
                    }
                })
            }

            if (collapse) {
                collapse.forEach((c) => {
                    var collapseElement = new BSN.Collapse(c);

                    var myCollapse = document.getElementById(c.getAttribute('href').replace('#', ''));

                    myCollapse.addEventListener('show.bs.collapse', function (event) {
                        // do something cool when .show() method is called
                        // event.target is myCollapseExample
                        var parent = event.target.parentNode;
                        var span = parent.querySelector('span.angle');
                        span.style = 'transform: rotate(135deg)'
                    }, false);

                    myCollapse.addEventListener('hide.bs.collapse', function (event) {
                        // do something cool when .show() method is called
                        // event.target is myCollapseExample
                        var parent = event.target.parentNode;
                        var span = parent.querySelector('span.angle');
                        span.style = 'transform: rotate(-45deg)'
                    }, false);
                })
            }
        },
        fixBreadcrumbMargin() {
            ['load', 'resize'].forEach((el) => {
                window.addEventListener(el, function () {
                    let headerHeight = document.querySelector('header').clientHeight;
                    let breadcrumbs = document.querySelector('.breadcrumb-list');

                    if (breadcrumbs) {
                        breadcrumbs.style.marginTop = headerHeight + 'px';
                    }
                })
            })
        },
        setFixedMenu() {
            let content = document.querySelector('.site-content'); // captura el target header
            let headerTag = document.querySelector('header'); // captura el target header
            let menuBrand = document.querySelector('#main-menu-logo'); // captura el logo
            let topBar = document.querySelector('.top').offsetHeight; // captura el height del header para saber cuando se paso con el scroll
            let startY = (headerTag.offsetHeight); // captura el height del header para saber cuando se paso con el scroll
            let vw = window.innerWidth; // captura el width del viewport
            let vwAvailable = 768; // Estabece la resolución a partir de la cual se puede ejecutar es script
            document.documentElement.style.setProperty('--top-header', topBar + "px");
            document.documentElement.style.setProperty('--header-is-fixed', startY + "px");

            ['resize', 'scroll'].forEach(function (event) {
                window.addEventListener(event, function () {
                    // if (vw > vwAvailable) {
                    if (window.pageYOffset > startY * .5) {
                        headerTag.classList.add("fixed");
                        // content.classList.add("header-is-fixed");
                        // menuBrand.classList.remove('d-lg-none');
                    } else {
                        headerTag.classList.remove("fixed");
                        // content.classList.remove("header-is-fixed");
                        // menuBrand.classList.add('d-lg-none');
                    }

                    if (window.pageYOffset > startY * .5) {
                        headerTag.classList.add("inView");
                        let nav = document.querySelector(`nav.navbar-light`);
                        nav.classList.remove(`py-3`);

                    } else {
                        headerTag.classList.remove("inView");
                        let nav = document.querySelector(`nav.navbar-light`);
                        nav.classList.add(`py-3`);
                    }
                    // }
                });
            })

            // Alternative to DOMContentLoaded event
            document.addEventListener('readystatechange', event => {
                if (event.target.readyState === 'complete') {
                    if (window.scrollY > startY) {
                        headerTag.classList.add("fixed");
                        // content.classList.add("header-is-fixed");
                        // menuBrand.classList.remove('d-lg-none');
                    } else {
                        headerTag.classList.remove("fixed");
                        // content.classList.remove("header-is-fixed");
                        // menuBrand.classList.add('d-lg-none');
                    }

                    if (window.pageYOffset > startY * 1.5) {
                        headerTag.classList.add("inView");
                        let nav = document.querySelector(`nav.navbar-light`);
                        nav.classList.remove(`py-3`);
                    } else {
                        headerTag.classList.remove("inView");
                        let nav = document.querySelector(`nav.navbar-light`);
                        nav.classList.add(`py-3`);
                    }
                }
            })
        },
        mMenuInit() {
            let imgDiv = document.querySelector('.brand.p-1');
            const mediaQuery = '(max-width: 992px)';
            const menu = new MmenuLight(
                document.querySelector("#menu"),
                mediaQuery
            );
            const navigator = menu.navigation({
                // options
                // title: "It's Kangaroo Time",
            });

            const drawer = menu.offcanvas({
                // options
            });

            //inserting image over the menu
            ['resize', 'load'].forEach((event) => {
                window.addEventListener(event, function () {
                    if (window.innerWidth < 992) {
                        let menuEl = document.querySelector('#menu').parentElement;
                        if(imgDiv)
                            menuEl.prepend(imgDiv);
                    } else {
                        document.querySelector('.brand.p-1') ? document.querySelector('.brand.p-1').remove() : null;
                    }
                })
            })

            //Fire de drawer
            document.querySelector('a[href="#menu"]')
                .addEventListener('click', (evnt) => {
                    evnt.preventDefault();
                    drawer.open();
                });
        }
    },
    computed: {},
    filters: {
        pluralize: function (vowl = false, value) {
            return vowl ? `${value}s` : `${value}es`;
        },
        capitalize(str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
        truncate: function (text, length) {
            if (text)
                return text.split(' ', length).join(' ') + '...';
        },
        dateFormat(date, options = null) {
            if (!options)
                options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };

            return new Date(date).toLocaleString('es-ES', options);
        },
        priceFormat: function (price) {
            return new Intl.NumberFormat('de-DE').format(price);
        }
    }
});

const loadPrototypes = () => {
    Array.prototype.isNotEmpty = function () {
        return this.length > 0
    }
    Array.prototype.isEmpty = function () {
        return !this.isNotEmpty()
    }
    String.prototype.isEmpty = function () {
        return this.length === 0
    }
}

store.dispatch('init').then(() => {
    new Vue({
        el: '#page',
        router,
        store,
        created() {
            // this.fixBreadcrumbMargin();
            loadPrototypes.call()
            //Traigo las categorias
            // this.$store.dispatch('fetch', {
            //     name: 'categorias',
            //     url: `${this.baseUrl}/${this.categoriesUrl}`
            // });
            document.dispatchEvent(new Event('vue-created'));
            console.log('created')
        },
        mounted() {
            //fixing the "skeleton showing" bug of mmenu during DOM load
            let menu = document.querySelector('#menu');
            menu.classList.remove('d-none');
            this.mMenuInit();
            this.setFixedMenu();
            this.BSN();
            this.$nextTick(() => {
                this.fixBreadcrumbMargin();
                document.dispatchEvent(new Event('vue-mounted'));
            })
        }
    })
})
